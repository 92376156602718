import React from 'react';

import Layout from 'components/layout';
import SEO from 'components/seo';
import Section from 'components/section';

import {
  Box, Text,
  getColor, getSpacing,
} from 'style-guide';

const IndexPage = () => (
  <Layout>
    <SEO title="About" />
    <Section
      title="About"
    >
      <Box pt={getSpacing('extra-loose')}>
        <Text type="heading">
          Hi everyone!
        </Text>
      </Box>
      <Box pt={getSpacing('extra-loose')}>
        <Text type="heading">
          {`My name is João! I'm a Brazilian Software Developer, married to Tamara Lavoier, my love with who I have two dogs, Pudim and Geléia. This is my family!`}
        </Text>
      </Box>
      <Box pt={getSpacing('extra-loose')}>
        <Text type="heading">
          {`
            I was born in 1987 in Araçatuba where I grew up, and I started programming my first lines of code when I was 14 years old with a Duron 900MHz. VBScript/ASP, MySQL, HTML, and CSS were introduced in my life.
            I used to create many web applications by myself at home. 2009 was my day one; I sold one of my applications to my boss and got hired to maintain that. That was built using Php, Html, Css, Postgre, and JavaScript.
          `}
        </Text>
      </Box>
      <Box pt={getSpacing('extra-loose')}>
        <Text type="heading">
          {`
            I completed my Bachelor's degree in Computer Science in 2011, and after that, 
            I worked using many technologies until I realized in 2015 that Front-End was a 
            great passion when I started working with ES6+, React, and its ecosystem, 
            implementing high-quality applications using TDD and CI.
          `}
        </Text>
      </Box>
      <Box pt={getSpacing('extra-loose')}>
        <Text type="heading">
          {`
            Today I am working for Rain rounded of great worldwide professionals, creating awesome stuff, and providing an excellent experience for thousands of employees in the United States.
          `}
        </Text>
      </Box>
      <Box pt={getSpacing('extra-loose')}>
        <Box mb={getSpacing('tight')}>
          <Text
            type="subheading"
            color={getColor()}
          >
            skills
          </Text>
        </Box>
        <Text type="heading">
          {`
            Actual languages: JavaScript(ES7+), Typescript;
            Libraries/Frameworks: React/React-Native, Next.js, Gatsby, Chakra.ui, Stitches, Styled-Components, Sass, Redux, Npm/Yarn, Webpack, Babel, Jest, Enzyme, React-Testing-Library, i18n-next, React-Query, SWR;
            Fundamentals: FP, OOP, Clean Code, Clean Architecture, Micro-Frontends, TDD, Restful;
            CI/CD: Github Actions, Azure Pipelines, Travis, Sonar, Coveralls;
            Databases: MySQL, Postgre;
            Agile: Scrum, User Story, Kanban, ORK's; `}
        </Text>
      </Box>
    </Section>
  </Layout>
);

export default IndexPage;
